import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Page Not Found" />
    <h1>NOT FOUND</h1>
    <p>
      You just hit a route/page that doesn&#39;t exist. If you think it should
      exist, let us know via call or message and we'd be happy to help.
    </p>
  </Layout>
)

export default NotFoundPage
